<template>
  <div id="reset-password">
    <FirstTemplateHome>
      <div v-if="statusSend === 0">
        <div class="text-center">
          <p class="mg-b-5 font-24">การตั้งรหัสผ่านใหม่</p>
        </div>
        <el-form
          @submit.native.prevent="submitResetPassword"
          label-position="top"
          :model="form"
          ref="ruleFormResetPassword"
          :rules="rules"
        >
          <el-form-item label="กรอกรหัสผ่านใหม่" prop="newPassword">
            <el-input
              v-model="form.newPassword"
              show-password
              placeholder="กรุณากรอกรหัสผ่านใหม่"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="ยืนยันรหัสผ่านใหม่อีกครั้ง" prop="password">
            <el-input
              v-model="form.password"
              show-password
              placeholder="กรุณากรอกรหัสผ่านยืนยัน"
            >
            </el-input>
          </el-form-item>

          <el-button
            type="warning"
            :loading="loadingReset"
            native-type="submit"
            round
            class="mg-t-2 mg-b-4 w-100"
            >เปลี่ยนรหัสผ่าน</el-button
          >
        </el-form>
      </div>
      <div v-else-if="statusSend === 1" class="text-center">
        <i class="fas fa-check-circle notification color-success mg-y-4"></i>
        <div>
          <h1 class="font-weight-400 mg-t-less color-35">
            ตั้งรหัสผ่านใหม่สำเร็จ
          </h1>
        </div>
        <div class="mg-t-5 mg-y-3 font-18">
          <span class="mg-r-3 color-35"
            >กรุณาเข้าสู่ระบบ เพื่อเข้าใช้งานระบบ</span
          >
        </div>
        <el-button
          type="warning"
          round
          class="mg-y-4 w-100"
          @click="$router.push(`/login`)"
          >กลับไปหน้า เข้าสู่ระบบ</el-button
        >
      </div>
    </FirstTemplateHome>
  </div>
</template>
<script>
import "@/mixin/FirstTemplateHome";
import { HTTP } from "@/service/axios";
import jwt from "jsonwebtoken";

export default {
  mounted() {
    window.localStorage.removeItem("UIC");
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("กรุณากรอกรหัสผ่าน"));
      } else {
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("กรุณากรอกรหัสผ่านอีกครั้ง"));
      } else if (value !== this.form.newPassword) {
        callback(new Error("รหัสผ่านไม่ตรงกัน"));
      } else {
        callback();
      }
    };
    return {
      statusSend: 0,
      form: {
        newPassword: "",
        password: "",
      },
      rules: {
        newPassword: [
          {
            required: true,
            message: "กรุณากรอกรหัสผ่านใหม่",
            trigger: ["blur", "change"],
          },
          {
            min: 8,
            message: "รหัสผ่านต้องมีความยาวไม่น้อยกว่า 8 ตัวอักษร",
            trigger: ["blur", "change"],
          },
          { validator: validatePass, trigger: ["blur", "change"] },
        ],
        password: [
          {
            required: true,
            message: "กรุณากรอกรหัสผ่านยืนยัน",
            trigger: ["blur", "change"],
          },
          {
            min: 8,
            message: "รหัสผ่านต้องมีความยาวไม่น้อยกว่า 8 ตัวอักษร",
            trigger: ["blur", "change"],
          },
          { validator: validatePass2, trigger: ["blur", "change"] },
        ],
      },
      arrayGet: null,
      loadingReset: false,
    };
  },
  mounted() {
    this.checkToken();
  },
  methods: {
    checkToken() {
      if (this.$route.query.token) {
        var token = jwt.verify(
          this.$route.query.token,
          process.env.VUE_APP_BASE_KEY_AUTH_EMAIL
        );
        this.arrayGet = token;
      } else {
        this.$router.push(`/login`);
      }
    },
    submitResetPassword() {
      this.$refs["ruleFormResetPassword"].validate((valid) => {
        if (valid) {
          this.loadingReset = true;
          let obj = {
            _id: this.arrayGet._id,
            email: this.arrayGet.email,
            password: this.form.password,
          };
          HTTP.post(`user/reset/password`, obj)
            .then((res) => {
              if (res.data.success) {
                this.$notify({
                  title: "ตั้งรหัสผ่านใหม่สำเร็จ!",
                  type: "success",
                  customClass: "success",
                });
                this.statusSend = 1;
              } else {
                this.$message({
                  message: "ข้อมูลไม่ถูกต้อง",
                  type: "error",
                  duration: 4000,
                });
              }
            })
            .catch((e) => {
              if (e.response.data.status == 422) {
                this.$message({
                  message: "ข้อมูลไม่ถูกต้อง",
                  type: "error",
                  duration: 4000,
                });
              }
            })
            .finally(() => {
              this.loadingReset = false;
            });
        }
      });
    },
  },
};
</script>
